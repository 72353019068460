import { createTheme, Button, Input, Title, Notification } from '@mantine/core';

import styles from './components.module.css';

export const theme = createTheme({
    primaryColor: 'blue',
    fontFamily: 'Zilla Slab, sans-serif',

    components: {
        Button: Button.extend({
            defaultProps: {
                size: 'md',
                radius: 'xs',
                variant: 'filled',
            },
            classNames: {
                root: styles.buttonRoot,
                label: styles.buttonLabel,
            },
        }),
        Input: Input.extend({
            classNames: {
                input: styles.inputInput,
            },
        }),
        Title: Title.extend({
          classNames: {
            root: styles.titleRoot,
          }
        }),
        Notification: Notification.extend({
            classNames: {
                root: styles.notificationRoot,
                title: styles.notificationTitle,
                description: styles.notificationDescription,
                body: styles.notificationBody,
                closeButton: styles.notificationCloseButton,
            },
        }),
        /*Title: Title.extend({
      vars: (theme, props) => ({
        if(props.size='xs') {
          return {
            root: {

            }
          }
        }
      })
    })*/
    },
});
