// Libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { inject } from '@vercel/analytics';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { registerSW } from "virtual:pwa-register";

// Router
import { BrowserRouter } from 'react-router-dom';

// Theming
import { MantineProvider } from '@mantine/core';
import { theme } from './styles/theme.ts';

// Components
import App from './app/App.tsx';

// Styles
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import './styles/index.css';

// Fonts
import '@fontsource-variable/josefin-sans';
import '@fontsource/zilla-slab/300.css';
import '@fontsource/zilla-slab/400.css';
import '@fontsource/zilla-slab/500.css';
import '@fontsource/zilla-slab/600.css';
import '@fontsource/zilla-slab/700.css';

// Vercel Injections
injectSpeedInsights();
inject();

// add this to prompt for a refresh
const updateSW = registerSW({
    onNeedRefresh() {
      if (confirm("New content available. Reload?")) {
        updateSW(true);
      }
    },
  });

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={new QueryClient()}>
                <ReactQueryDevtools initialIsOpen={false} />
                <MantineProvider theme={theme}>
                  
                    <App />
                </MantineProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
);
