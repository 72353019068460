import { Navigate } from 'react-router-dom';
import { useUser } from '@/auth/auth.store.ts';

interface ProtectedRoutesProps {
    children: JSX.Element;
    role: string;
}


function ProtectedRoute({ children, role}: ProtectedRoutesProps) {

    // Hooks
    const user = useUser();

    if(!user) {
        return <Navigate to='/login' />;
    }

    if(role && !user.roles.includes(role)) {
        return <Navigate to='/login' />;
    }

    if(role && user.roles.includes(role)) {
        return children;
    }

    
};

export default ProtectedRoute;