// Router
import { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Auth
import { PrivateRoutes } from '@/auth/PrivateRoutes';

// Pages
import Splash from '@/pages/splash/Splash';
const Dashboard = lazy(() => import('@/pages/dashboard/Dashboard'));
const Trends = lazy(() => import('@/pages/trends/Trends'));
const Settings = lazy(() => import('@/pages/settings/Settings'));

// Styles
import '@mantine/core/styles.css';

// Components
import RootLayout from './RootLayout';
import ProtectedRoute from '@/auth/ProtectedRoute';
const Admin = lazy(() => import('@/pages/admin/Admin'));

export default function App() {
    return (
        <Routes>
            <Route path='*' element={<RootLayout />}>
                <Route path='' element={<Navigate to='/login' replace />} />
                <Route path='login' element={<Splash />} />
                <Route element={<PrivateRoutes />}>
                    <Route path='dashboard' element={<Dashboard />} />
                    <Route path='trends' element={<Trends />} />
                    <Route path='settings' element={<Settings />} />
                    {/*<Route path='onboarding' element={<Onboarding />} />*/}
                </Route>
                <Route path='admin' element={
                    <ProtectedRoute role='admin'>
                        <Admin />
                    </ProtectedRoute>
                } />
                <Route path='*' element={<Navigate to='/login' replace />} />
            </Route>
        </Routes>
    );
}
